import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import STYLES from '../../constants/styles';
import Bio from './bio';

function SideBar() {
  return (
    <SideBarWrapper>
      <SideBarContent>
        <h3>About Me</h3>
        <ProfilePic>
          <StaticImage
            src="../../static/images/profile-pic.jpg"
            alt="Silvia Pan Profile Picture"
          />
        </ProfilePic>
        <Bio />
      </SideBarContent>
    </SideBarWrapper>
  );
}

const SideBarWrapper = styled.div`
  height: 100%;
  display: none;

  @media (min-width: ${STYLES.DESKTOP_SIZE}) {
    display: block;
  }
`;

const SideBarContent = styled.div`
  background-color: white;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const ProfilePic = styled.div`
  margin-bottom: 0.5rem;
`;

export default SideBar;
