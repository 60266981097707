import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import STYLES from '../../constants/styles';
import Layout from '../templates/layout';
import Seo from '../components/seo';

import SideBar from '../components/sidebar';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="Silvia Pan"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <ContentWrapper>
          <BlogPreviewsWrapper>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              return (
                <BlogPreview key={node.fields.slug}>
                  <Link to={node.fields.slug}>
                    <PublishDate>{node.frontmatter.date}</PublishDate>
                    <BlogTitle>{title}</BlogTitle>
                    <Excerpt>
                      {node.frontmatter.description || node.excerpt}
                      <ActionItem>Read more</ActionItem>
                    </Excerpt>
                  </Link>
                </BlogPreview>
              );
            })}
          </BlogPreviewsWrapper>
          <SideBar />
        </ContentWrapper>
      </Layout>
    );
  }
}

export default BlogIndex;

const ContentWrapper = styled.div`
  display: flex;

  @media (min-width: ${STYLES.DESKTOP_SIZE}) {
    display: grid;
    grid-template-columns: minmax(20rem, 2fr) minmax(10rem, 1fr);
    grid-gap: 1rem;
  }
`;

const BlogPreviewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: 1rem;
  margin-bottom: 2rem;
`;

const BlogPreview = styled.div`
  background-color: white;
  padding: 1rem;

  @media (min-width: ${STYLES.DESKTOP_SIZE}) {
    &:first-child {
      grid-column: 1/3;
    }
  }
`;

const BlogTitle = styled.h3`
  margin-bottom: 1rem;
`;

const PublishDate = styled.small`
  color: ${STYLES.META_COLOR};
`;

const Excerpt = styled.p`
  margin: 0.5rem 0;
`;

const ActionItem = styled.small`
  color: ${STYLES.ACCENT_COLOR};
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 0.25rem;
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
