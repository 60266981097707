import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { social } = data.site.siteMetadata;
        return (
          <div>
            <p>
              Hi there, I'm <strong>Silvia</strong> and work as a software
              engineer. I write development tutorials and share advice on how to
              start a career in tech.
            </p>
            <SocialMedia>
              <a href={`https://linkedin.com/in/${social.linkedin}`}>
                Connect with me
              </a>
            </SocialMedia>
          </div>
        );
      }}
    />
  );
}

const SocialMedia = styled.small`
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 0.25rem;
`;

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        social {
          linkedin
        }
      }
    }
  }
`;

export default Bio;
